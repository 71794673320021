<template>
  <NotLogged
    :welcome="welcome"
    formTitle="Żądanie resetu hasła"
    :afterMessage="message"
    :afterMessageType="alertVariant"
  >
    <template #form>
      <form @submit.prevent="onSubmit">
        <input
          id="input-1"
          @focus="message = ''"
          ref="email"
          name="email"
          v-model="form.email"
          placeholder="adres email"
          type="email"
          :class="{'border-red-500': message.length > 0 }"
          :disabled="isBusy"
          required
        >
        <button type="submit" class="btn btn-primary w-full rounded-sm mt-2" :disabled="isBusy">
          <span v-if="!isBusy">Wyslij żądanie</span>
          <span v-else>Wysyłam...</span>
        </button>
        <div class="block text-right mb-4 mt-1">
          <router-link :to="{ name: 'login' }">Powrót do strony logowania</router-link>
        </div>
      </form>
    </template>
  </NotLogged>
</template>

<script>
import axios from 'axios';
import NotLogged from '../components/NotLogged.vue';

export default {
  name: 'PasswordEmail',
  components: {
    NotLogged,
  },
  data() {
    return {
      message: '',
      alertVariant: 'danger',
      form: {
        email: '',
      },
      isBusy: false,
      welcome: {
        content: `Twoje hasło przetrzymujemy w bazie danych w formie zaszyfrowanej, więc nie możemy Ci go przypomnieć.
            Wpisz w pole obok swój adres email powiązany z Twoim kontem, abyśmy mogli na Twoją skrzynkę przesłać link resetujący hasło.
            Po otrzymaniu maila postepuj zgodnie z instrukcją.
            <br>
            Jeśli nie znajdziesz maila w skrzynce odbiorczej, sprawdź folder "spam". W ostateczności możesz skontaktowac się
            z administratorem w celu ustawienia nowego hasła.`,
        footer: 'Zespół Estimates Likwidacja Szkód',
      },
    };
  },
  mounted() {
    this.$refs.email.focus();
  },
  methods: {
    onSubmit() {
      this.isBusy = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/password/email`, this.form)
        .then(response => {
          this.alertVariant = 'success';
          this.message = response.data.message;
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response !== undefined) {
            this.alertVariant = 'danger';
            this.message = error.response.data.message;
          } else {
            this.alertVariant = 'danger';
            this.message = 'Wystapił nieznany błąd, być może serwer jest zajęty, spróbuj ponownie później';
          }
          this.isBusy = false;
        });
    },
  },
};
</script>
